import React from 'react'
import PropTypes from 'prop-types'

export const HTMLContent = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

const Content = ({ content, className }) => {
  //console.log(content.props.value);
  //let katexProcessedContent =
  //content.props.value += "mno";

  return (
    // <div>
    //    <p>HERE!!!</p>
    <div className={className}>{content}</div>
    // </div>
  )
}

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
