import React from 'react'
import PropTypes from 'prop-types'
//import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
//import { graphql, Link } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const CategoryPostTemplate = ({
  content,
  contentComponent,
  title,
    helmet,
    nodetype,
    children,
}) => {
    const PostContent = contentComponent || Content

    console.log("nodetype and children", nodetype, children)

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
          <PostContent content={content} />
          <br/>
          <p><b>Node type:</b> {nodetype}</p>
          {children && children.length ? (
                  <div style={{ marginTop: `4rem` }}>
                  <h4>Children</h4>
                  <ul>
                  {children.map(child => (
                          <li key={child + `child`}>{child}
                      </li>
                  ))}
              </ul>
                  </div>
          ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

CategoryPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const CategoryPost = ({ data }) => {
  const { markdownRemark: post } = data
    //console.log("post is", post);
    // eslint-disable-next-line
    const origRetVal = (<Layout>
                        <CategoryPostTemplate
                        content={post.html}
                        contentComponent={HTMLContent}
                        helmet={
                                <Helmet titleTemplate="%s | Category">
                                <title>{`${post.frontmatter.title}`}</title>
                                </Helmet>
                        }
                        title={post.frontmatter.title}
                        nodetype={post.frontmatter.nodetype}
                        children={post.frontmatter.children}
                        />
                        </Layout>)

    const title = post.frontmatter.title
    const nodetype = post.frontmatter.nodetype
    const children = post.frontmatter.children

    const obj = //{"id":77757}
          {
              "title": title,
              "nodetype": nodetype,
              "children": children
          }
    
  return JSON.stringify(obj)
}

CategoryPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default CategoryPost

export const pageQuery = graphql`
  query CategoryPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        nodetype
        children
      }
    }
  }
`

// export const ItemPostTemplate = ({
//   content,
//   contentComponent,
//     internalnotes,
//     difficulty,
//   tags,
//   title,
//   helmet,
// }) => {
//   const PostContent = contentComponent || Content

//   return (
//     <section className="section">
//       {helmet || ''}
//       <div className="container content">
//         <div className="columns">
//           <div className="column is-10 is-offset-1">
//             <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
//               {title}
//             </h1>
//             <p>{internalnotes}</p>
//             <p>{difficulty}</p>
//             <PostContent content={content} />
//             {tags && tags.length ? (
//               <div style={{ marginTop: `4rem` }}>
//                 <h4>Tags</h4>
//                 <ul className="taglist">
//                   {tags.map(tag => (
//                     <li key={tag + `tag`}>
//                       <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//             ) : null}
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

// ItemPostTemplate.propTypes = {
//   content: PropTypes.node.isRequired,
//   contentComponent: PropTypes.func,
//   internalnotes: PropTypes.string,
//   difficulty: PropTypes.number,
//   title: PropTypes.string,
//   helmet: PropTypes.object,
// }

// const ItemPost = ({ data }) => {
//   const { markdownRemark: post } = data

//   return (
//     <Layout>
//       <ItemPostTemplate
//         content={post.html}
//         contentComponent={HTMLContent}
//         internalnotes={post.frontmatter.internalnotes}
//         difficulty={post.frontmatter.difficulty}
//         helmet={
//           <Helmet titleTemplate="%s | Item">
//             <title>{`${post.frontmatter.title}`}</title>
//             <meta
//               name="internalnotes"
//               content={`${post.frontmatter.internalnotes}`}
//             />
//           </Helmet>
//         }
//         tags={post.frontmatter.tags}
//         title={post.frontmatter.title}
//       />
//     </Layout>
//   )
// }

// ItemPost.propTypes = {
//   data: PropTypes.shape({
//     markdownRemark: PropTypes.object,
//   }),
// }

// export default ItemPost

// export const pageQuery = graphql`
//   query ItemPostByID($id: String!) {
//     markdownRemark(id: { eq: $id }) {
//       id
//       html
//       frontmatter {
//         date(formatString: "MMMM DD, YYYY")
//         title
//         internalnotes
//         difficulty
//         tags
//       }
//     }
//   }
// `
